import {Component} from 'react'
import Api, {Cookie} from "../Shared/Api"

export default class SignApi extends Component{

    constructor(props) {
        super(props)

        this.data = {app:'', email:Cookie('meEmail'), code:'', phone:Cookie('mePhone'), country:Cookie('meCountry'), from:1, mode:1}

        this.apiUrl = "https://akademy.digitalservices.ma"
        //this.apiUrl = `${Api.defaults.baseURL}signin` 
        //this.apiUrl = "https://localhost:44371"

        //alert(`SignApi app: ${this.props.app} , api: ${this.apiUrl}`)

        this.headers = {            
            'Accept':'application/json',
            'Content-Type':'application/json'
        }
        this.message=""
    }

    setData(app) {
        this.data.app = app
        //this.data.email = Cookie('meEmail')
        //alert(`setData ${app} ${this.data.app} ${email} ${this.data.email}`)
    }

    
    isPhoneReady(value) {
        if(value && value.length>9) {
            const phoneRegex = /^\d+$/;
            if (phoneRegex.test(value)) {
                this.data.phone = value
                this.message="Next"
                return true
            }
        }
        this.message="Invalid Phone"
    }
    
    isEmailReady(value) {
        if(value && value.length>5 && value.includes("@") && value.includes(".")) {
            const emailRegex = /\S+@\S+\.\S+/;
            if (emailRegex.test(value)) {
                this.data.email = value
                this.message="Next"
                return true
            }
        }
        this.message="Invalid Email"
    }

    isDataReady(from) {
        this.data.from=from

        if(this.data.mode===1) {
            if(from===2 && this.isPhoneReady(this.data.phone) && this.data.country>0)
                return true
            else if(from===1 && this.isEmailReady(this.data.email))
                return true

        }
        else if(this.data.mode===2 && this.data.code.length===5) {
            return true
        }
    }

    isCode() {
        //const num = /^\d+$/;
        if(this.data.mode===2 && this.data.code.length===5) {
            const phoneRegex = /^\d+$/;
            if (phoneRegex.test(this.data.code)) {
                return true
            }
        }
    }

    async POST(onMode) {

        //console.log('POST data :', this.data)       
        let response = await fetch(this.apiUrl+"/signin", {
            method:'POST',
            headers:this.headers,
            body:JSON.stringify(this.data)
        })
        //console.log('POST response',response)
        if(response.ok){
            let result = await response.json()
//            console.log('POST result',result)
            if(result?.message) {
                this.message=result.message

                if(this.message.includes("Salaam"))// && result.role)
                {
                    if(this.data.mode===1) {
                        this.data.mode=2
                    }
                    else if(this.data.mode===2) {
                        localStorage.setItem(Api.defaults.storage, JSON.stringify({
                            connected:true, ...result}   
                        ))
                        this.data.mode=3
                    }
                    onMode(this.data.mode)
                    return true
                }
            }
            else {
                this.message="Error api: result message is null?"
            }
        }
        else {
            this.message="Error api: response invalid?"
        }
        //console.log('POST message',this.message)
    }
}

