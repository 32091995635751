import React from 'react';
import {Form} from 'react-bootstrap'

const InText = ({name,value,label,type,size,onChange,readOnly=false}) => {

    return (
        <Form.Group controlId={`InText-${name}`} className='form-row conter'>
            
            <Form.Control
            className={ (name==='code') ? 'col-sm-9 text-center' : (name==='Phone')?'col-sm-12 text-center':'col-sm-12 text-right' }
            type={type ?? 'text'}
            name={name}                
            placeholder={label ?? name}
            defaultValue={value}
            style={{fontSize:size??'1.5rem', fontWeight:'bold', color:'blue', margin:'1rem 0 1rem 0'}}               
            readOnly={readOnly}
            onChange={onChange}
            //  required
            />
            {/*<Form.Label className='col-sm-2' align='right'>:{label ?? name}</Form.Label>*/}
        </Form.Group>        
    )
}

const InDetail = ({name,value,label,readOnly,size,onChange}) => {

    return (
        <Form.Group controlId={`InDetail-${name}`} className='form-row conter' width='100%'>
            <textarea className="form-control text-right" rows="2" 
                name={name} 
                type="text"
                style={{fontSize:size??'1.5rem', marginTop:'1rem'}}
                defaultValue={value ??''} 
                align='right'
                placeholder={label ?? name}
                readOnly={readOnly??false}  
                onChange={onChange}          
            />
        </Form.Group>
    )
}

/*
In React, components need to be capitalized !
*/
const InCheck = (name, value=false, mode=0, label) => {
    const [checked, setChecked] = React.useState(value)
    return (
        <div className="form-group form-row">
      <label className="control-label col-sm-2" style={{width:"auto", textAlign:"right"}}>
          {label ?? name}: </label>
          <div className="col-sm-1">
            <input type="checkbox" name={name}
            defaultChecked={checked}
            onChange={() => setChecked(!checked)}
            readOnly={(mode??0)===0}
            className="form-control" 
            />        
        </div>
      </div>     
    )
}

export {InText, InDetail, InCheck}
