import React from 'react'
import './Salaat.css'

const Salawaat = ({serash, api}) => {

    return (
        <div className='Salawaat' >

<Salaat name="Tajwyd" 
  icon="/images/tajwyd.png" 
  info={"أكاديمة التجويد"} 
  plan="PRO"
  start={"Tajwyd"}
  to={"https://tajwyd.akademy.ma"}
/>

</div>
    )

}

const Salaat = ({name, icon, info, plan, start, to, style}) => {

    //alert(info)

    return (
        <div className='Salaat' style={style}>
            <div className="center">
                <a target="_blank" rel="noopener noreferrer" href={to}>
                    <img src={to+icon} alt={name+": "+info} />
                </a>
            </div>
            <br/>
            <div className="ServiceName">
                <h1 style={{fontWeight: 'bold'}}>{name}</h1>
                <h4 className="ServiceInfo">{info}</h4>
                <br/>
                <h3 className="ServiceInfo">{plan}</h3>
                {style && start?.length>0 ?
                <div style={{width:'100%', position:'relative'}}>
                <a href={to} target="_blank" rel="noreferrer" >
                    <button className='Button'
                        style={to.includes('telegram') ? {backgroundColor:'rgb(40,170,240)'} : null }
                    >
                    {start}
                    </button>
                </a>
                </div>
                : null }
            </div>
        </div>
    )
}

export {Salaat, Salawaat}
