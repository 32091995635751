import React, {useState, useEffect} from 'react'
import './Service.css'
import {Get} from '../Shared/Api'

const Services = ({api, member}) => {

    const domain = 3
    const [loaded, Load] = useState(false)
    const [items , Items] = useState([])
  
    useEffect(()=>{        
  
      if(domain>0) {
        Get(`/service/${domain}`, Items, Load)
        console.log(items)
      }
  
  },[domain])
  
    return (
        <div className='Services' >

        {items.map((item) => (
            <Service name={item.Name} 
            icon={item.Icon} 
            info={item.Info}
            plan={item.Plan}
            start={item.Start}
            to={item.Link}
          />
        ))}

</div>
    )

}

const Service = ({name, icon, info, plan, start, to, style}) => {

    //alert(info)

    return (
        <div className='Service' style={style}>
            <div className="center">
                <a target="_blank" rel="noopener noreferrer" href={to}>
                    <img src={icon.startsWith('https') ? icon : to+icon} alt={name+": "+info} />
                </a>
            </div>
            <br/>
            <div className="ServiceName">
                <h1 style={{fontWeight: 'bold'}}>{name}</h1>
                <h4 className="ServiceInfo">{info}</h4>
                <br/>
                <h3 className="ServiceInfo">{plan}</h3>
                {style && start?.length>0 ?
                <div style={{width:'100%', position:'relative'}}>
                <a href={to} target="_blank" rel="noreferrer" >
                    <button className='Button'
                        style={to.includes('telegram') ? {backgroundColor:'rgb(40,170,240)'} : null }
                    >
                    {start}
                    </button>
                </a>
                </div>
                : null }
            </div>
        </div>
    )
}

export {Service, Services}

