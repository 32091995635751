import React, {useEffect, useState} from 'react'
import {Row, Col, Navbar, Button} from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import SignModal from './Sign/SignModal'
import SearchControl from './Shared/SearchControl'
import OnCall from './Shared/ActionControl';

const Header = ({onSearch, onEtat, route, layout, logo, connected, onConnect}) => {
    const app = "Akademy"
    const [showing, Showing] = useState(false)

    useEffect(() => {
        let store = localStorage.getItem(app)
        //console.log('localStorage',store)
        if(store) {
            let infos = JSON.parse(store)
            
            onConnect(infos?.connected)
        }
    },[])

    const Logout = (e) => {
        localStorage.removeItem(app)
        onConnect(false)
        window.location.reload()
    }

    const Login = (e) => {
        Showing(true)
    }

    const bstyle = { width: '6rem', display: 'block', margin:'0.2rem', fontSize:'1.2rem' }  

    return (
        
        <div className="Header" id="Header">

            <Col width="100px">
                <a href="/">
                    <img className="Logo" src={logo} alt="Home" />
                </a>
            </Col>

            <Col>            
            <Row className="navbar navbar-custom" expand="lg">
            <Col style={{width:'auto'}}>
                
            </Col>            
            <Col sm={3}>
                { connected ?
                <SearchControl onSearch={onSearch} />
                : 
                <Navbar>
                    <NavLink className="d-inline p-3 navbar-custom" to="/signup" >
                        <i className="fa fa-edit"> Register</i></NavLink>  
                    
                </Navbar>                
                }
            </Col>
            <Col sm={3}>
                { connected ?
                    <Button style={bstyle} variant="outline-secondary"
                        onClick={Logout}
                    ><i className="fa fa-sign-out"> Exit</i></Button>
                :
                <Button style={bstyle} variant="outline-primary"
                onClick={Login}
            ><i className="fa fa-sign-in"> Login</i></Button>

                }
            </Col>   
                
            </Row>
            </Col>

{connected?
    null
:    
    <OnCall />
}

<SignModal 
    app={app}
    show={showing}
    logo={logo}
    onHide={()=>{Showing(false)}}
    onEtat={onEtat}
/>               
        </div>
        
        
    )
}

export default Header;