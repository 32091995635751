import React from 'react';

const OnCall = () => {
  return (
    <div className='ActionContainer'  id='contact'>
      <a className='ActionButton' rel='noopener noreferrer' target='_blank'
        href="mailto:contact@akademy.ma?subject=I want to join you&body=Salaam, I am interested in your Akademy, thank you for accepting my membership request.">
        Join Us
      </a>
    </div>
  );
};

export default OnCall;
