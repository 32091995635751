import React, {Component} from 'react'
import {Modal, Col, Form, Button} from 'react-bootstrap'
import {CountryControl} from '../Shared/CountryControl'
import {ToNotify} from 'fast_inon'
import {InText, InDetail} from '../Shared/InputControl'
import { Service } from '../Service/Services'

import SignApi from './SignApi'

const signApi = new SignApi()

export default class SignUp extends Component{

    constructor(props){
        super(props)

        this.state = {snackbarmsg:'', status:'', open:false, code:'', valid:false, time:'', confirmed:''}
        this.onSubmit = this.onSubmit.bind(this)

        this.device = {code:'',country:'',phone:'' }
        this.logo = 'logo.png'
    }

    static device

    componentDidMount() {
        this.interval = setInterval(() => {
            this.onCheckLinked()
            this.setState({ time: Date.now() })
        
        }, 5000);
      }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    onOpen = (event) => {
        //Collapse
        this.setState({open:!this.state.open});
    }

    onCheckLinked = () => {        
        if(this.state.confirmed.length===0 ) {
            //console.log('linked.device: ',this.device, this.state.time)

            if(this.device.code>=100000 && this.device.phone.length>=9 ) {
                signApi.POST('/setting/linked', this.device)
                .then(response=>{
                    //console.log('linked.response: ',response.data)
                    if(response.data?.id>0) {
                        this.setState({confirmed:response.data.message})
                    }
                    this.setState({snackbarmsg:response.data.message}); 
                })
                .catch(error => {        
                    //console.log('signup error:',error)
                    //window.location.reload()
                })
            }
        }
    }

    onSubmit(event) {
        event.preventDefault()
        let item = {
            app: 'Tajwyd',
            name: event.target.Name.value,
            country: event.target.CountryId.value,
            phone: event.target.Phone.value,
            email: event.target.Email.value,
            infos: event.target.Infos.value,
        }

        console.log('signup item:',item)
        signApi.POST('/signup', item)
        .then(response=>{
          //console.log('signup response:', response.data)
          this.setState({snackbarmsg:response.data.message, status:'مرحبا'})
  
          if(response?.data?.message && response.data.message.includes("Salaam")) {   
            this.setState({code:response.data.message})
            this.device = {                
                phone:item.phone,
                country:item.country,
                code:response.data.code
            }
            //console.log('/Sign: ',this.device)
          }
        })
        .catch(error => {        
          //console.log('signup error:',error)
          this.setState({snackbarmsg:error.message}); 
      })
    }

    onChange = (e) => {
        let phone=e.target.value
        this.setState({valid:false})
        if(phone.length>=9)
        {
            const phoneRegex = /^\d+$/
            if (phoneRegex.test(phone)) {
                this.setState({valid:true})
            }
        }
    }

    render() {

        return (
            <div className="mt-5 d-flex justify-content-center">


<ToNotify 
message={this.state.snackbarmsg}
onClose={this.snackbarClose}
/>


            <Modal.Body>

{
this.props.connected ?
<Col sm={8} >
    
<h1>{this.state.code} مرحبا</h1>

</Col>
:

 <>
     <h3 style={{ display: 'flex', justifyContent: 'flex-end', marginBottom:'1rem', color:'darkgreen' }}>التسجيل في الموقع</h3>
     <Col sm={8} >

{ this.state.code.length>5 
?
    <Service style={{left:'10%', width:'80%', height:'100%'}} 
    icon="images/telegram.png" 
    info={"Please send this code by Telegram"} 
    plan={this.state.code} 
    start={"Telegram/AkademyMaBot"}
    to="https://telegram.me/AkademyMaBot"
    />
:        
    <Form onSubmit={this.onSubmit}>
         <div className="degrade-green" style={{border:"12px solid darkgreen", borderRadius:"15px", padding:"2rem 3rem 1rem 3rem", justifyContent:"center"}}>         
            
            <h5 style={{color:"darkgreen", margin:"2rem"}}>{this.state.confirmed}</h5>
            
            <InText name="Name" label="الأسم الثلاثي" required/>
            <CountryControl apiUrl={signApi.apiUrl} label='البلد'/> 
            <InText name="Phone" required label="رقم الهاتف" onChange={this.onChange} />
            <InText name="Email" type="email" label="بريد إلكتروني" />
            <InDetail name="Infos" label="معلومات إضافية"/>
        </div>

<Form.Group style={{justifyContent:"center", alignItems:"center", display: "flex"}}>
                <Button variant="success" type="submit" style={{width:"auto", fontSize:"1.1rem", margin:"1.5rem"}}>
                <i className="fa fa-save"></i> Save    
                </Button>
            </Form.Group>

         </Form>
            }
     </Col>
 </>
 
    }
            </Modal.Body>
 
            </div>
        )
    }
}

