import {Services} from './Service/Services'

const Home = ({route, layout, connected}) => {

        return (
            <div className="Home">

    <Services />
            </div>
        )
}

export default Home


/*
{   connected   ?
    <Services />
:
<div style={{margin:'1rem'}}>
    
<h1>مرحبًا، الرجاء الدخول أو التسجيل</h1>
<h2>Welcome, please login or sign up</h2>
</div>
}
*/