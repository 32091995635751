import React, {useState, useEffect, useCallback, useRef} from 'react'
import {Route, Routes, BrowserRouter} from 'react-router-dom'
import './App.css'

import Home from './components/Home'
import Header from './components/Header'
import Footer from './components/Footer'
import SignUp from './components/Sign/SignUp'
import { Salawaat } from './components/Salaat/Salaat'

function App() {
  const logo = 'logo.png'
  const route = useRef("/")
  const [search, Search]  = useState("")
  const [etat, Etat]  = useState("")
  const [connected, Connected] = useState(false)
  const [layout, Layout] = useState(window.matchMedia('(orientation:landscape)').matches)

  const onResize = useCallback(event => {
    let value=window.matchMedia('(orientation:landscape)').matches    
    Layout(value)
  }, [])

  useEffect(() => {
          window.addEventListener("resize", onResize);
          return () => {
              window.removeEventListener("resize", onResize);
          };
  }, [onResize, layout])

  return (
<div className='App'>

<BrowserRouter>

  <Header onSearch={Search} route={route} layout={layout} logo={logo} onEtat={Etat} connected={connected} onConnect={Connected} />

  <Routes>
    <Route path="/" element={ <Home search={search} onEtat={Etat} connected={connected} />} />
    <Route path='/signup' element={ <SignUp connected={connected} />} />
    <Route path='/salaat' element={ <Salawaat search={search} />} />
  </Routes>

  <Footer etat={etat}/>

</BrowserRouter>

</div>

)

}

export default App;


/**
 *   <Navigate to={route.current} />
 * <Route path='/' component={() => <Home search={search} onEtat={Etat} />} exact />

 */